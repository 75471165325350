var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"xxx",staticClass:"content"},[_c('p',{staticClass:"lianxi"},[_vm._v("骨骼发育的特性")]),_c('p',{staticClass:"lianxiadress"},[_vm._v("根据大数据统计，儿童骨骼发育具有以下五大特性")]),_vm._m(0),_c('p',{staticClass:"lianxi"},[_vm._v("骨骼测评匹配建议")]),_c('p',{staticClass:"lianxiadress"},[_vm._v("根据测评数据为孩子提出适合他的运动建议或矫正产品")]),_vm._m(1),_c('div',{staticClass:"dianji"},[_vm._m(2),_c('div',[_c('div',{on:{"click":function($event){return _vm.xiazhifayu('xiazhi')}}},[_c('img',{attrs:{"src":require("../assets/image/img_xzfycp.png"),"alt":""}}),_vm._m(3)]),_c('p',[_vm._v("下肢发育测评")])]),_c('div',[_c('div',{on:{"click":function($event){return _vm.xiazhifayu('qugan')}}},[_c('img',{attrs:{"src":require("../assets/image/img_qgfycp.png"),"alt":""}}),_vm._m(4)]),_c('p',[_vm._v("躯干发育测评")])]),_c('div',[_c('div',{on:{"click":function($event){return _vm.xiazhifayu('jichu')}}},[_c('img',{attrs:{"src":require("../assets/image/img_jcfaycp.png"),"alt":""}}),_vm._m(5)]),_c('p',[_vm._v("基础发育测评")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shuju"},[_c('div',[_c('span'),_c('h3',[_vm._v("足弓发育测评")]),_c('div',[_c('i'),_c('span',[_vm._v("测量并评估人体足弓发育不良或正常")])]),_c('div',[_c('i'),_c('span',[_vm._v(" 光学反射足底印记，系统自动计算足弓比值")])]),_c('div',[_c('i'),_c('span',[_vm._v(" 对预防足部问题和保护足弓起着重要的意义")])])]),_c('div',[_c('span'),_c('h3',[_vm._v("跟骨发育测评")]),_c('div',[_c('i'),_c('span',[_vm._v("测量并评估人体跟骨")])]),_c('div',[_c('i'),_c('span',[_vm._v("系统自动计算出跟骨发育详细数值")])]),_c('div',[_c('i'),_c('span',[_vm._v("对预防足部问题和保护跟骨处于中立位起着重要的意义")])])]),_c('div',[_c('span'),_c('h3',[_vm._v("足底压力分析")]),_c('div',[_c('i'),_c('span',[_vm._v("测量人体足底压力分布情况")])]),_c('div',[_c('i'),_c('span',[_vm._v("获取静态和动态下，足底压力分布情况")])]),_c('div',[_c('i'),_c('span',[_vm._v("对预防足部问题和缓解足底受力起着重要的意义")])])]),_c('div',[_c('span'),_c('h3',[_vm._v("3D足部扫描")]),_c('div',[_c('i'),_c('span',[_vm._v("扫描足部360°，获取足部三维模型")])]),_c('div',[_c('i'),_c('span',[_vm._v("初步评估足弓及跟骨发育情况")])]),_c('div',[_c('i'),_c('span',[_vm._v("为个性化定制提供重要的数据")])])]),_c('div',[_c('span'),_c('h3',[_vm._v("体格测量")]),_c('div',[_c('i'),_c('span',[_vm._v("测量身高")])]),_c('div',[_c('i'),_c('span',[_vm._v("测量体重")])]),_c('div',[_c('i'),_c('span',[_vm._v("作为其他部位发育的参考依据")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"biaoge"},[_c('div',[_c('img',{attrs:{"src":require("../assets/image/opin01.jpg"),"alt":""}})]),_c('div',{staticClass:"biaoge-right"},[_c('div',{staticClass:"topth"},[_c('span',{staticClass:"firstspan",staticStyle:{"color":"#fff"}},[_vm._v("发育指数")]),_c('span',{staticClass:"secondspan",staticStyle:{"color":"#fff"}},[_vm._v("匹配建议")])]),_c('div',[_c('span',{staticClass:"firstspan",staticStyle:{"color":"#000"}},[_vm._v("0—20")]),_c('span',{staticClass:"secondspan",staticStyle:{"color":"#000"}},[_vm._v("建议去专业的儿童医院")])]),_c('div',[_c('span',{staticClass:"firstspan",staticStyle:{"color":"#000"}},[_vm._v("20—60")]),_c('span',{staticClass:"secondspan",staticStyle:{"color":"#000"}},[_vm._v("防护气垫C3 防护气垫C4 矫形鞋")])]),_c('div',[_c('span',{staticClass:"firstspan",staticStyle:{"color":"#000"}},[_vm._v("60—80")]),_c('span',{staticClass:"secondspan",staticStyle:{"color":"#000"}},[_vm._v("防护气垫C2 防护气垫C3 江博士")])]),_c('div',{staticClass:"botth"},[_c('span',{staticClass:"firstspan",staticStyle:{"color":"#000"}},[_vm._v("80—100")]),_c('span',{staticClass:"secondspan",staticStyle:{"color":"#000"}},[_vm._v("防护气垫C1 防护气垫C2 防护气垫C3 专业的运动指导建议")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('img',{attrs:{"src":require("../assets/image/img_zufycp.png"),"alt":""}})]),_c('p',[_vm._v("足部发育测评")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('p',[_vm._v("点击")]),_c('p',[_vm._v("查看")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('p',[_vm._v("点击")]),_c('p',[_vm._v("查看")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('p',[_vm._v("点击")]),_c('p',[_vm._v("查看")])])
}]

export { render, staticRenderFns }