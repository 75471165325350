var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('p',{staticClass:"lianxi"},[_vm._v("下肢发育测评")]),_c('p',{staticClass:"lianxiadress"},[_vm._v("通过对下肢在矢状面、冠状面、水平面的不同状态下所采集的数据，并结合其相关的其他部位的测评数据将多组数据进行组合分析，指导健康发育。")]),_vm._m(0),_c('p',{staticClass:"lianxi"},[_vm._v("骨骼测评匹配建议")]),_c('p',{staticClass:"lianxiadress"},[_vm._v("根据测评数据为孩子提出适合他的运动建议或矫正产品")]),_vm._m(1),_c('div',{staticClass:"dianji"},[_c('div',[_c('div',{on:{"click":function($event){return _vm.xiazhifayu('zubu')}}},[_c('img',{attrs:{"src":require("../assets/image/img_zufycp.png"),"alt":""}}),_vm._m(2)]),_c('p',[_vm._v("足部发育测评")])]),_vm._m(3),_c('div',[_c('div',{on:{"click":function($event){return _vm.xiazhifayu('qugan')}}},[_c('img',{attrs:{"src":require("../assets/image/img_qgfycp.png"),"alt":""}}),_vm._m(4)]),_c('p',[_vm._v("躯干发育测评")])]),_c('div',[_c('div',{on:{"click":function($event){return _vm.xiazhifayu('jichu')}}},[_c('img',{attrs:{"src":require("../assets/image/img_jcfaycp.png"),"alt":""}}),_vm._m(5)]),_c('p',[_vm._v("基础发育测评")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shuju"},[_c('div',[_c('span'),_c('h3',[_vm._v("膝关节冠状面测评")]),_c('div',[_c('i'),_c('span',[_vm._v("测量并评估人体膝关节冠状面（正面）")])]),_c('div',[_c('i'),_c('span',[_vm._v("获取膝关节冠状面详细数值")])]),_c('div',[_c('i'),_c('span',[_vm._v("对下肢力线和膝关节具有重要意义")])])]),_c('div',[_c('span'),_c('h3',[_vm._v("膝关节矢状面测评")]),_c('div',[_c('i'),_c('span',[_vm._v("测量并评估人体膝关节矢状面（侧面）")])]),_c('div',[_c('i'),_c('span',[_vm._v("获取膝关节矢状面发育数值")])]),_c('div',[_c('i'),_c('span',[_vm._v(" 对下肢力线和膝关节具有重要意义")])])]),_c('div',[_c('span'),_c('h3',[_vm._v("下肢发育测评")]),_c('div',[_c('i'),_c('span',[_vm._v(" 测量并评估人体双下肢")])]),_c('div',[_c('i'),_c('span',[_vm._v("获取双下肢发育数值")])]),_c('div',[_c('i'),_c('span',[_vm._v(" 对下肢力线具有重要意义")])])]),_c('div',[_c('span'),_c('h3',[_vm._v("骨盆测评")]),_c('div',[_c('i'),_c('span',[_vm._v("测量并评估人体骨盆")])]),_c('div',[_c('i'),_c('span',[_vm._v("获取骨盆发育数值")])]),_c('div',[_c('i'),_c('span',[_vm._v(" 对人体力线起着承上启下稳定支撑的重要作用")])])]),_c('div',[_c('span'),_c('h3',[_vm._v("足部3D扫描")]),_c('div',[_c('i'),_c('span',[_vm._v(" 扫描足部360°，获取足部三维模型")])]),_c('div',[_c('i'),_c('span',[_vm._v("初步评估足弓及跟骨发育情况")])]),_c('div',[_c('i'),_c('span',[_vm._v("为个性化定制提供重要的数据")])])]),_c('div',[_c('span'),_c('h3',[_vm._v("体格测量")]),_c('div',[_c('i'),_c('span',[_vm._v(" 测量身高")])]),_c('div',[_c('i'),_c('span',[_vm._v("测量体重")])]),_c('div',[_c('i'),_c('span',[_vm._v("作为其他部位发育的参考依据")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"biaoge"},[_c('div',[_c('img',{attrs:{"src":require("../assets/image/opin02.jpg"),"alt":""}})]),_c('div',{staticClass:"biaoge-right"},[_c('div',{staticClass:"topth"},[_c('span',{staticClass:"firstspan",staticStyle:{"color":"#fff"}},[_vm._v("发育指数")]),_c('span',{staticClass:"secondspan",staticStyle:{"color":"#fff"}},[_vm._v("匹配建议")])]),_c('div',[_c('span',{staticClass:"firstspan",staticStyle:{"color":"#000"}},[_vm._v("0—20")]),_c('span',{staticClass:"secondspan",staticStyle:{"color":"#000"}},[_vm._v("建议去专业的儿童医院")])]),_c('div',[_c('span',{staticClass:"firstspan",staticStyle:{"color":"#000"}},[_vm._v("20—60")]),_c('span',{staticClass:"secondspan",staticStyle:{"color":"#000"}},[_vm._v("防护气垫C3 防护气垫C4 踝足矫形器 膝内/外翻矫形器")])]),_c('div',[_c('span',{staticClass:"firstspan",staticStyle:{"color":"#000"}},[_vm._v("60—80")]),_c('span',{staticClass:"secondspan",staticStyle:{"color":"#000"}},[_vm._v("防护气垫C1 防护气垫C2 防护气垫C3")])]),_c('div',{staticClass:"botth"},[_c('span',{staticClass:"firstspan",staticStyle:{"color":"#000"}},[_vm._v("80—100")]),_c('span',{staticClass:"secondspan",staticStyle:{"color":"#000"}},[_vm._v("防护气垫C1 防护气垫C2 防护气垫C3 专业的运动指导建议")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('p',[_vm._v("点击")]),_c('p',[_vm._v("查看")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('img',{attrs:{"src":require("../assets/image/img_xzfycp.png"),"alt":""}})]),_c('p',[_vm._v("下肢发育测评")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('p',[_vm._v("点击")]),_c('p',[_vm._v("查看")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('p',[_vm._v("点击")]),_c('p',[_vm._v("查看")])])
}]

export { render, staticRenderFns }